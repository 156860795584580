import { XCircleIcon } from '@heroicons/react/24/solid'
import { Button, Input } from '@/client/components'
import { useEmailSignInForm } from './Form/form'

export const signInErrors = {
  AccessDenied: 'You do not have access to Klu.',
  Signin: 'You do not have access to Klu.',
  OAuthSignin: 'You do not have access to Klu.',
  OAuthCallback: 'You do not have access to Klu.',
  OAuthCreateAccount: 'You do not have access to Klu.',
  EmailCreateAccount: 'You do not have access to Klu.',
  Callback: 'You do not have access to Klu.',
  OAuthAccountNotLinked:
    'To confirm your identity, sign in with the same account you used originally.',
  EmailSignin: 'Check your email address.',
  CredentialsSignin: 'Sign in failed. Check the details you provided are correct.',
  default: 'An error occurred while trying to sign you in. Please try again.',
}

export type SigninError = keyof typeof signInErrors

export function SignInErrorCard({ error }: { error: SigninError }) {
  const errorMessage = error && (signInErrors[error] ?? signInErrors.default)
  return (
    <div className="mt-6 rounded-md bg-red-50 ring-1 ring-red-100 p-4 text-left">
      <div className="flex">
        <div className="flex-shrink-0 pt-0.5">
          <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-md font-medium text-red-600">Authentication error</h3>
          <div className="mt-2 text-sm">{errorMessage}</div>
        </div>
      </div>
    </div>
  )
}

export function EmailSignIn() {
  const { formik } = useEmailSignInForm()
  const { values, errors, handleChange, handleSubmit, isSubmitting } = formik

  return (
    <div>
      <div className="grid gap-2 pt-4">
        <div className="grid gap-1">
          <Input
            id="email"
            placeholder="jeff@bezos.com"
            type="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            label=""
            message={errors.email}
            hasError={!!errors.email}
            value={values.email}
            onChange={handleChange}
          />
        </div>
        <div className="grid gap-1">
          <Input
            id="password"
            placeholder="******"
            type="password"
            label=""
            message={errors.password}
            hasError={!!errors.password}
            value={values.password}
            onChange={handleChange}
          />
        </div>
        <Button
          variant="outline"
          className="font-light"
          loading={isSubmitting}
          onClick={() => void handleSubmit()}
        >
          Continue with Email
        </Button>
      </div>
    </div>
  )
}
