import { useFormik, type FormikConfig } from 'formik'
import { signIn } from 'next-auth/react'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { userAuthSchema } from '@/lib/validations'
import type { UserAuthFormInputs } from './types'

export const useEmailSignInForm = () => {
  const initialValues = React.useMemo(() => {
    const initialValues: UserAuthFormInputs = {
      email: '',
      password: '',
    }

    return initialValues
  }, [])

  const onSubmit = React.useCallback(async (values: UserAuthFormInputs) => {
    await signIn('credentials', {
      ...values,
      redirect: true,
      callbackUrl: '/',
    })
  }, [])

  const formikConfig: FormikConfig<UserAuthFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(userAuthSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
