import * as z from 'zod'

export const userAuthSchema = z.object({
  email: z.string().email(),
  password: z.string().min(4),
})

export const userSignupSchema = z.object({
  email: z.string().email(),
  password: z.string().min(4),
  confirmPassword: z.string().min(4),
})

export const newPasswordSchema = z.object({
  password: z.string().min(6, {
    message: 'Minimum of 6 characters required',
  }),
  confirmPassword: z.string().min(6, {
    message: 'Minimum of 6 characters required',
  }),
})

export const resetSchema = z.object({
  email: z.string().email({
    message: 'Email is required',
  }),
})
